import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import { Button, Icon } from 'semantic-ui-react';
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from 'react-i18next';
import "../../components/ModalPopup.scss"
import NavHeader from "../header/Header";
import defaultImage from "../../assets/svgs/default_image.jpg";
import CSS from "./FuneralAdminPage.module.scss";
import UploadClipsToStream from './UploadClipsToStream';
import {
	getFuneralStreams,
	getFuneralDetails,
	startStream,
	stopStream,
    changeStateToEnded,
	resendStartSignal,
	sendStopSignal,
    initialStateVal,
    extendStream
} from "../../redux/funeral/actions";
import { getFuneralHome } from "../../redux/funeralHome/actions";
import "../../components/ModalPopup.scss";
import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';
import PersonIcon from '@mui/icons-material/Person';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import Spinner from "../../components/Spinner";
const convertDateToDateOnly = (unformattedDate) => {
	if (unformattedDate) {
		return moment(unformattedDate).format("YYYY-MM-DD");
	}
	return "";
};

const convertDateToTimeOnly = (unformattedDate) => {
	if (unformattedDate) {
		return moment(unformattedDate).format("h:mm A");
	}
	return "";
};

const FuneralInfo = ({ funeral }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className={CSS.deceasedDetails}>
                <div className={CSS.profileImgWithDetails}>
                    <img
                        src={funeral.deceasedImageUrl ? funeral.deceasedImageUrl : defaultImage}
                        alt="Profile"
                        className={CSS.profileImg}
                    />
                    <div className={CSS.deceasedPersonalDetails}>
                        <div className={CSS.firstLastName}>
                            <span>{funeral.firstName}{"  "}</span>
                            <span>{funeral.lastName}</span>
                        </div>
                        <div className={CSS.dobDod}>
                            <span>{funeral.dateOfBirth}{" - "}</span>
                            <span>{funeral.dateOfDeath}</span>
                        </div>
                    </div>
                </div>
                <div className={CSS.streamDetails}>
                    <div className={CSS.streamCount}>
                        {funeral.streams?.length}{" "}{t('pages.admin_funeral_page.streams')}
                    </div>
                    <div className={CSS.streams}>
                        {funeral.streams?.map((stream) => (
                            <div key={stream.id} className={CSS.eventItem}>
                                <div className={CSS.eventHeader}>
                                    {stream.name}
                                </div>
                                <div className={CSS.eventTime}>
                                    {convertDateToDateOnly(stream.streamStart)},{" "}
                                    {convertDateToTimeOnly(stream.streamStart)} -
                                    {convertDateToTimeOnly(stream.streamEnd)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const FuneralFaq = () => {
    const { t } = useTranslation();
	return(
		<Fragment>
			<div className={CSS.faq}>
				<div className={CSS.faqHint}>
					<LiveHelpRoundedIcon
						className={CSS.faqHintIcon}
					/>
					<span>{t('pages.admin_funeral_page.faq')}</span>
				</div>
				<div className={CSS.faqStatements}>
					<span className={CSS.faqStatementSubtext}>{t('pages.admin_funeral_page.faq_question_one')}</span>
					<span className={CSS.faqStatementSubtext2}>{t('pages.admin_funeral_page.faq_answer_one')}</span>
				</div>
				<div className={CSS.faqStatements}>
					<span className={CSS.faqStatementSubtext}>{t('pages.admin_funeral_page.faq_question_two')}</span>
					<span className={CSS.faqStatementSubtext2}>{t('pages.admin_funeral_page.faq_answer_two')}</span>
				</div>
				<div className={CSS.faqStatements}>
					<span className={CSS.faqStatementSubtext}>{t('pages.admin_funeral_page.faq_question_three')}</span>
					<span className={CSS.faqStatementSubtext2}>{t('pages.admin_funeral_page.faq_answer_three')}</span>
				</div>
			</div>
		</Fragment>
	)
}

const FuneralVisitors = ({ visitors }) => {
    const { t } = useTranslation();

    // Define styles object for reusable styles
    const styles = {
        container: {
            width: '100%',
            marginTop: '1rem'
        },
        header: {
            display: 'flex',
            backgroundColor: '#f5f5f5',
            fontWeight: 'bold',
            padding: '12px 8px',
            borderBottom: '2px solid #ddd',
            '@media (max-width: 768px)': {
                display: 'none'
            }
        },
        headerCell: {
            flex: 1,
            padding: '8px'
        },
        row: {
            display: 'flex',
            borderBottom: '1px solid #ddd',
            backgroundColor: 'white',
            '@media (max-width: 768px)': {
                flexDirection: 'column',
                padding: '12px 8px'
            }
        },
        alternateRow: {
            backgroundColor: '#f9f9f9'
        },
        cell: {
            flex: 1,
            padding: '12px 8px',
            display: 'flex',
            alignItems: 'center',
            '@media (max-width: 768px)': {
                padding: '4px 0',
                justifyContent: 'space-between'
            }
        },
        mobileLabel: {
            display: window.innerWidth <= 768 ? 'block' : 'none',
            fontWeight: 'bold',
            marginRight: '8px'
        },
        content: {
            wordBreak: 'break-word'
        }
    };

    return (
        <Fragment>
            <div className={CSS.faq}>
                <div className={CSS.faqHint}>
                    <PersonIcon className={CSS.faqHintIcon} />
                    <span>{t('pages.admin_funeral_page.visitors_title')}</span>
                </div>

                {visitors?.length === 0 && (
                    <div className={CSS.faqStatementSubtext}>
                        {t('pages.admin_funeral_page.no_visitors')}
                    </div>
                )}

                <div style={styles.container}>
                    <div style={styles.header}>
                        <div style={styles.headerCell}>{t('pages.admin_funeral_page.name')}</div>
                        <div style={styles.headerCell}>{t('pages.admin_funeral_page.email')}</div>
                        <div style={styles.headerCell}>{t('pages.admin_funeral_page.joined_at')}</div>
                    </div>
                    {visitors && visitors.map((visitor, index) => (
                        <div 
                            key={visitor.id} 
                            style={{
                                ...styles.row,
                                ...(index % 2 === 1 ? styles.alternateRow : {})
                            }}
                        >
                            <div style={styles.cell}>
                                <div style={styles.mobileLabel}>{t('pages.admin_funeral_page.name')}</div>
                                <div style={styles.content}>{visitor.name}</div>
                            </div>
                            <div style={styles.cell}>
                                <div style={styles.mobileLabel}>{t('pages.admin_funeral_page.email')}</div>
                                <div style={styles.content}>{visitor.email}</div>
                            </div>
                            <div style={styles.cell}>
                                <div style={styles.mobileLabel}>{t('pages.admin_funeral_page.joined_at')}</div>
                                <div style={styles.content}>
                                    {moment(visitor.createdAt).format('MMM D, YYYY h:mm A')}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

const StreamPlayer = ({ stream }) => {
    if (!stream.livestreamEvent && !stream.dacastEvent) {
        return (
            <div className={CSS.streamNotStarted} >
                Event not ready yet. Please refresh the page to try again.
            </div>
        );
    }

    if (stream.livestreamEvent) {
        if (stream.livestreamEvent.isVimeo) {
            return (
                <iframe 
                    src={`https://vimeo.com/event/${stream.livestreamEvent.eventId}/embed/interaction`}
                    width="300"
                    height="200"
                />
            )
        }
        
        return (
            <iframe id="ls_embed_1641182855" 
                src={`https://livestream.com/accounts/29883600/events/${stream.livestreamEvent.eventId}/player?width=300&height=200&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false`}
                width="300" 
                height="200"
            />
        );  
    } else if (stream.dacastEvent) {
        return (
            <iframe id="ls_embed_1641182855" 
                src={"https://iframe.dacast.com/live/23278b4c-f8a8-77e8-163d-bb4d08b951b0/" + stream.dacastEvent.eventId}
                width="300" 
                height="200"
            />  
        );   
    }
}

const StreamControl = ({ 
    stream,
    startStream,
    stopStream,
    user,
    updateFuneralStreams,
    resendStartSignal,
    sendStopSignal,
    loading,
    cameraError,
    funeralClips,
    funeralHomeInfo,
    extendStream,
    changeStateToEnded,
    getFuneralStreams
}) => {
	const [isStreaming,
        setIsStreaming] = useState(stream.state === 'started' || stream.state === 'paused');
	const [modalOpen,
        setModalOpen] = useState(false);
    const [extendTime, setExtendTime] = useState(0);
	const canStream = stream.livestreamEvent || stream.dacastEvent;
	const [streamOpen, setStreamOpen] = useState({});
    const [isCameraRestarting, setIsCameraRestarting] = useState(false);
	const closeModal = () => setModalOpen(false);
    const { t } = useTranslation();

    const clipsForStream = funeralClips.filter((clip) => {
        return clip.streamId === stream.id;
    });
    
  	return (
        <div className={CSS.eachStreamData}>
			{loading && <Spinner funeralSpinner={true}/>}
            <div className={CSS.eachStreamDetails}>
                <div className={CSS.eachStreamName}>
                    {stream.name}
                </div>
                {stream.state === "not_started" ? 
                    <div className={CSS.streamNotStartedState}>
                        x {t('pages.admin_funeral_page.not_started')}
                    </div>
                : stream.state === "started" ? 
                    <div className={CSS.streamStartedState}>
                        <PlayArrowIcon/>
                        Started
                    </div>
                : stream.state === "ended" ? 
                    <div className={CSS.streamEndedState}>
                        <StopCircleIcon />
                        Ended
                    </div>
                : stream.state === "paused" ? 
                    <div className={CSS.streamPausedState}>
                        <PauseCircleIcon />
                        Paused
                    </div>
                : stream.state === "starting" ? 
                    <div className={CSS.streamStartedState}>
                        Preview
                    </div>
                :
                    ""
                }
            </div>
            <div className={CSS.eachStreamDetails}>
                <div className={CSS.streamStartStopDetails}>
                    <div className={CSS.streamData}>
                        <span className={CSS.streamDataSubtext}>{t('pages.admin_funeral_page.date')}: </span>
                        <span className={CSS.streamDataSubtext2}>{convertDateToDateOnly(stream.streamStart)}</span>
                    </div>
                    <div className={CSS.streamData}>
                        <span className={CSS.streamDataSubtext}>{t('pages.admin_funeral_page.time')}: </span>
                        <span className={CSS.streamDataSubtext2}>{convertDateToTimeOnly(stream.streamStart)}{"-"}{convertDateToTimeOnly(stream.streamEnd)}</span>
                    </div>
                    <div className={CSS.streamData}>
                        <span className={CSS.streamDataSubtext}>{t('pages.admin_funeral_page.room')}: </span>
                        <span className={CSS.streamDataSubtext2}>{stream.room?.name}</span>
                    </div>
                </div>
                <StreamPlayer stream={stream} />
            </div>
            {
                stream.state === 'ended' && (<b>{t('pages.admin_funeral_page.video_processed')}</b>)
            }
            {
                !canStream && (<p>{t('pages.admin_funeral_page.not_ready_to_stream')}</p>)
            }
            {
                stream.location !== 'On-Site' && (<p>{t('pages.admin_funeral_page.offsite_stream_note')}</p>)
            }
            {canStream && (stream.location === 'On-Site'  && stream.state !== 'ended') &&
                <div className={CSS.eachStreamBtns}>
                    {!isStreaming ?
                        <button
                            onClick={async() => {
                                await startStream(stream.funeralId, stream.id);
                                await updateFuneralStreams();
                                setIsStreaming(true);
                            }}
                            className={`${CSS.actionBtn} ${CSS.warning} ${CSS.browseBtn}`}
                        >
                            {t('pages.admin_funeral_page.start_stream')}
                        </button>
                        :
                        <button
                            onClick={async() => {
                                setModalOpen(true);
                            }}
                            className={`${CSS.actionBtn} ${CSS.error} ${CSS.browseBtn}`}
                        >
                            {t('pages.admin_funeral_page.stop_stream')}
                        </button>
                    }
                    {stream.state === 'started' && (
                        <button
                            onClick={async () => {
                                await sendStopSignal(stream.funeralId, stream.id);
                                await updateFuneralStreams();
                            }}
                            className={`${CSS.actionBtn} ${CSS.primary} ${CSS.browseBtn}`}
                        >
                            {t('pages.admin_funeral_page.pause_camera')}
                        </button>
                    )}
                    {stream.state === 'paused' && (
                        <button
                            onClick={async () => {
                                await resendStartSignal(stream.funeralId, stream.id);
                                await updateFuneralStreams();
                            }}
                            className={`${CSS.actionBtn} ${CSS.primary} ${CSS.browseBtn}`}
                        >
                            {t('pages.admin_funeral_page.start_camera')}
                        </button>
                    )}
                    {stream.state === 'not_started' && (
                        <button
                            onClick={async () => {
                                await resendStartSignal(stream.funeralId, stream.id);
                                // toggleStream(stream.id);
                                await updateFuneralStreams();
                            }}
                            className={`${CSS.actionBtn} ${CSS.primary} ${CSS.browseBtn}`}
                        >
                            {t('pages.admin_funeral_page.preview_camera')}
                        </button>
                    )}
                    {stream.state === 'starting' && (
                        <button
                            onClick={async () => {
                                await sendStopSignal(stream.funeralId, stream.id);
                                await updateFuneralStreams();
                            }}
                            className={`${CSS.actionBtn} ${CSS.primary} ${CSS.browseBtn}`}
                        >
                            {t('pages.admin_funeral_page.stop_preview')}
                        </button> 
                    )}
                    {isStreaming && 
                        <button
                            onClick={async () => {
                                setIsCameraRestarting(true);
                                await sendStopSignal(stream.funeralId, stream.id);
                                await resendStartSignal(stream.funeralId, stream.id);
                                await updateFuneralStreams();
                                setIsCameraRestarting(false);
                            }}
                            className={`${CSS.actionBtn} ${CSS.clean} ${CSS.browseBtn}`}
                        >
                            {t('pages.admin_funeral_page.restart_camera')}
                        </button> 
                    }
                    {cameraError && 
                        <div style={{
                            fontFamily: "Bitter, serif",
                            fontStyle: "normal",
                            fontWeight: 300,
                            fontSize: 12,
                            color: "#AE0000",
                        }}>
                            {cameraError}
                        </div>
                    }
                    {user.user && user.user?.role === "super_admin" &&
                        <button
                            onClick={async () => {
                                await changeStateToEnded(stream.funeralId, stream.id);
                            }}
                            className={`${CSS.actionBtn} ${CSS.primary} ${CSS.browseBtn}`}
                        >
                            {t('pages.admin_funeral_page.change_state_to_ended')}
                        </button> 
                    }
                </div>
            }
            <Modal open={modalOpen} onClose={closeModal}>
                <div className={"modalPopup2Wrapper"}>
                    <div className={"modalPopupHeaderWrapper"}>
                        <div className={"modalPopupTitle"} style={{paddingRight: "16px", wordBreak: "break-word"}}>
                        {t('pages.admin_funeral_page.stream_end_confirmation')}
                        </div>
                        <div className={"modalPopupCloseBtn"}>
                            <CloseIcon
                                className={"modalPopupCloseIcon"}
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                    <div className={"modalPopupBody"} style={{padding: "0px 24px 16px", overflow: "hidden"}}>
                        <div className="modalHint">
                            {t('pages.admin_funeral_page.stream_end_note')}
                        </div>
                        <div className="btns">
                            <button
                                className={"cancelBtn"}
                                onClick={closeModal}>
                                {t('pages.admin_funeral_page.cancel')}
                            </button>
                            <button
                                className={"commitBtn"}
                                style={{backgroundColor : "rgba(174, 0, 0, 1)"}}
                                onClick={(e) => {
                                    stopStream(stream.funeralId, stream.id);
                                    setTimeout(() => {
                                        updateFuneralStreams();
                                    }, 1000);
                                    setIsStreaming(false);
                                    closeModal()
                                }}>
                                {t('pages.admin_funeral_page.confirm_stop_stream')}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            {(stream.automatedJobs.length > 0 || (stream.state === "started" && funeralHomeInfo.automatedControl)) && (
                <div style={{ marginTop: 10, marginBottom: 20, border: '1px dashed lightgray', borderRadius: '8px', background: 'rgba(211, 211, 211, 0.5)', padding: '10px', textAlign: 'center' }}>
                    {stream.automatedJobs.length > 0 && (
                        <div>
                            <span className={CSS.streamDataSubtext}>{t('pages.admin_funeral_page.automations')}</span>
                            {stream.automatedJobs.map((automatedJob) => {
                                if(automatedJob.jobType === "start_stream_livestream") {
                                    return <p key={automatedJob.id}>{t('pages.admin_funeral_page.start_auto_note')}{" "}{convertDateToDateOnly(automatedJob.runAt)}, {convertDateToTimeOnly(automatedJob.runAt)}</p>
                                } else if(automatedJob.jobType === "stop_stream_livestream") {
                                    return <p key={automatedJob.id}>{t('pages.admin_funeral_page.stop_auto_note')}{" "}{convertDateToDateOnly(automatedJob.runAt)}, {convertDateToTimeOnly(automatedJob.runAt)}</p>
                                }
                            })}
                        </div>  
                    )}
                    {stream.state === "started" && funeralHomeInfo.automatedControl && (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 15 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button basic color='grey' icon='minus' onClick={() => setExtendTime(extendTime  - 5 >= 0 ? extendTime  - 5 : 0)} />
                                <span style={{ margin: '0 10px' }}>{extendTime}{" "}{t('pages.admin_funeral_page.minutes')}</span>
                                <Button color='blue' icon='plus' onClick={() => setExtendTime(extendTime  + 5 <= 30 ? extendTime  + 5 : 30)} />
                            </div>
                            <Button secondary style={{ marginTop: '10px' }} disabled={extendTime === 0} onClick={
                                async () => { 
                                    await extendStream(stream.funeralId, stream.id, extendTime);
                                    setExtendTime(0);
                                }}>{t('pages.admin_funeral_page.extend_stream')}</Button>
                        </div>
                    )}
                </div>
            )}
            <div className={CSS.eachStreamDetails} style={{ marginTop: 15 }}>
                <div className={CSS.streamStartStopDetails}>
                    <div className={CSS.streamData}>
                        <span className={CSS.streamDataSubtext}>{t('pages.admin_funeral_page.upload_clips')}</span>
                        <UploadClipsToStream stream={stream} clipsForStream={clipsForStream} />
                    </div>
                </div>
            </div>
        </div>
  );
};


const FuneralAdminPage = (props) => {
	const {
		match: {
			params: { id: funeralId },
		},
	} = props;
	const { funeralDetails, funeralStreams, funeralClips, startStream, stopStream, initialStateVal, loading, cameraError, funeralHomeInfo, extendStream, user, changeStateToEnded } = props;

	useEffect(() => {
		props.getFuneralDetails(funeralId, true, true);
		props.getFuneralStreams(funeralId, false);
	}, []);

	useEffect(() => {
		if(funeralDetails.funeralHomeId){
			props.getFuneralHome(funeralDetails.funeralHomeId, true);
		}
	}, [funeralDetails.funeralHomeId]);

	const updateFuneralStreams = () => {
		props.getFuneralStreams(funeralId, false);
	}
	return (
		<div>
			<NavHeader />
			<div className={CSS.body}>
				<FuneralInfo funeral={funeralDetails} />
				<FuneralFaq />
				<div className={CSS.streamsValidity}>
					{funeralStreams.map((stream) => (
						<StreamControl
							key={stream.id}
							stream={stream}
							startStream={startStream}
							stopStream={stopStream}
							updateFuneralStreams={updateFuneralStreams} 
							resendStartSignal={props.resendStartSignal}
							sendStopSignal={props.sendStopSignal}
                            loading={loading}
                            cameraError={cameraError}
                            funeralClips={funeralClips}
                            funeralHomeInfo={funeralHomeInfo}
                            extendStream={extendStream}
                            changeStateToEnded={changeStateToEnded}
                            getFuneralStreams={getFuneralStreams}
                            user={user}
						/>
					))}
				</div>
                <FuneralVisitors visitors={funeralDetails.funeralVisitors} />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	funeralStreams: state.funeral.funeralStreams,
    funeralClips: state.funeral.funeralClips,
	funeralDetails: state.funeral.funeralDetails,
	funeralComments: state.funeral.funeralComments,
	funeralHomeInfo: state.funeralHome.funeralHomeViewing,
    cameraPreviewResponse: state.funeral.cameraPreviewRes,
	loading: state.funeral.showLoading,
    cameraError: state.funeral.cameraError,
    user: state.user.currentUser,
});

const mapDispatchToProps = {
	getFuneralStreams,
	getFuneralDetails,
	getFuneralHome,
	startStream,
	stopStream,
	resendStartSignal,
	sendStopSignal,
	initialStateVal,
    extendStream,
    changeStateToEnded
};
export default connect(mapStateToProps, mapDispatchToProps)(FuneralAdminPage);
