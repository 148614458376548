import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import "../../components/ModalPopup.scss";
import NavHeader from "../header/Header";
import defaultImage from "../../assets/svgs/default_image.jpg";
import CSS from "../deceasedPage/FuneralAdminPage.module.scss";
import CSS1 from "../orgManagement/OrganizationManagementView.module.scss";
import { getFuneralHome } from "../../redux/funeralHome/actions";
import "../../components/ModalPopup.scss";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import axios from '../../utils/axios';
import LiveHelpRoundedIcon from "@mui/icons-material/LiveHelpRounded";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import Spinner from "../../components/Spinner";
import {useParams} from 'react-router-dom';

const convertDateToDateOnly = (unformattedDate) => {
	if (unformattedDate) {
		return moment(unformattedDate).format("YYYY-MM-DD");
	}
	return "";
};

const convertDateToTimeOnly = (unformattedDate) => {
	if (unformattedDate) {
		return moment(unformattedDate).format("h:mm A");
	}
	return "";
};

const StreamPlayer = ({ stream }) => {
	if (!stream.livestreamEvent) {
		return (
			<div className={CSS.streamNotStarted}>
				Event not ready yet. Please refresh the page to try again.
			</div>
		);
	}

    if (stream.livestreamEvent.isVimeo) {
        return (
            <iframe
                id="ls_embed_1641182855"
                src={`https://vimeo.com/event/${stream.livestreamEvent.eventId}/embed/interaction`}
                width="300"
                height="200"
            />
        );   
    }

	return (
		<iframe
			id="ls_embed_1641182855"
			src={`https://livestream.com/accounts/29883600/events/${stream.livestreamEvent.eventId}/player?width=300&height=200&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false`}
			width="300"
			height="200"
		/>
	);
};

const FuneralHomeDetailsPage = (props) => {
    const [selectedNumber, setSelectedNumber] = useState(0);

	const {
		match: {
			params: { id: funeralHomeId },
		},
	} = props;
	
    const {
		getFuneralHome,
        funeralHomeInfo,
        user
	} = props;
	
    const [tabIndex, setTabIndex] = useState("0");
    const HandleTabIndexChange = (evt, index) => {
		setTabIndex(index + "");
	};

    useEffect(() => {
        getFuneralHome(funeralHomeId);
    }, []); 

    const handleSave = async () => {
        // Add your save logic here
        const data = { credits: selectedNumber };
        await axios.post(`api/funeral_homes/${funeralHomeId}/add_credits_to_funeral_home`, data, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        });
        
        setSelectedNumber(0);
        getFuneralHome(funeralHomeId);
    };

    if (user.user?.role !== 'super_admin') {
        return (
            <div>
                <NavHeader />
                <section className={CSS1.sectionWrapper} style={{padding: "24px 50px"}}>
                    Unauthorized page
                </section>
            </div>
        )
    }
    
	return (
		<div>
			<NavHeader />
            <section className={CSS1.sectionWrapper} style={{padding: "24px 50px"}}>
                {funeralHomeInfo && (
                    <span className={CSS1.pageTitle}>
                        {funeralHomeInfo.name}
                    </span>
                )}
                <div className={`${CSS1.segment} ${CSS1.tabsContainer}`}>
                    <div style={{marginBottom: "5px"}}>
                        <span className={CSS1.homeDetailTitle} style={{marginBottom: "5px"}}>Credits</span>
                        <div>
                            {funeralHomeInfo.credits} credits
                        </div>
                    </div>
                    <span className={CSS1.homeDetailTitle} style={{marginBottom: "5px"}}>Add Credits</span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <select
                            value={selectedNumber}
                            onChange={(e) => setSelectedNumber(parseInt(e.target.value))}
                            style={{ marginRight: '10px', padding: '5px', fontSize: '16px' }}
                        >
                            {Array.from({ length: 21 }, (_, i) => i - 10).map((num) => (
                            <option key={num} value={num}>
                                {num}
                            </option>
                            ))}
                        </select>
                        <button
                            className={`${CSS.actionBtn} ${CSS.primary}`}
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <span className={CSS1.homeDetailTitle} style={{marginBottom: "5px"}}>Automated Control?</span>
                        <div>
                            {funeralHomeInfo.automatedControl ? 'Yes' : 'No'}
                        </div>
                    </div>
                </div>
                <div className={`${CSS1.segment} ${CSS1.noPadding} ${CSS1.tabsContainer}`}>
                    <TabContext value={tabIndex}>
                        <TabList
                            onChange={HandleTabIndexChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example">
                            {funeralHomeInfo &&
                                funeralHomeInfo.rooms?.map(
                                    (room, index) => (
                                        <Tab
                                            key={room.id}
                                            label={room.name}
                                            value={index + ""}
                                        />
                                    )
                                )}
                        </TabList>
                            {funeralHomeInfo.rooms?.map(
                                (room, index) => (
                                    <TabPanel key={room.id} value={index + ""}>
                                        <div className={CSS1.homeDetailsPanel}>
                                            <div className={CSS1.homeDetails}>
                                                <div className={CSS1.homeDetailWrapper}>
                                                    <span className={CSS1.homeDetailTitle}>
                                                        Configuration Status:
                                                    </span>
                                                    <span className={CSS1.homeDetailValue} style={{ paddingRight: 5 }}>
                                                        {room.configuration ? 'Configured' : 'Not Configured'}
                                                    </span>
                                                </div>
                                                <br />
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                        <div className={CSS1.emptyTestScreen}>

                                        </div>
                                        <div className={CSS1.checkCameraBtns}>
                                            <button
                                                onClick={async () => {
                                                }}
                                                className={`${CSS.actionBtn} ${CSS.primary}`}
                                            >
                                                Pause camera
                                            </button>
                                            <button className={`${CSS.actionBtn} ${CSS.warning}`} 
                                                onClick={(e)=>{}}>
                                                Start Camera
                                            </button>
                                            <button className={`${CSS.actionBtn} ${CSS.error}`} 
                                                onClick={(e)=>{}}>
                                                Stop Camera
                                            </button>
                                        </div>
                                    </TabPanel>
                                )
                            )}
                    </TabContext>
                </div>
            </section>
		</div>
	);
};

const FuneralInfo = (funeralHomeDetails) => {
	return (
		<Fragment>
            {funeralHomeDetails.funeralHomeDetails &&
                <div className={CSS.deceasedDetails}>
                    <div className={CSS.profileImgWithDetails}>
                        <img
                            src={funeralHomeDetails.funeralHomeDetails.logoUrl ? funeralHomeDetails.funeralHomeDetails.logoUrl : defaultImage }
                            alt="Profile"
                            className={CSS.profileImg}
                            style={{borderRadius: "0%"}}
                        />
                        <div className={CSS.deceasedPersonalDetails}>
                            <div className={CSS.firstLastName}>
                                <span>
                                    {funeralHomeDetails.funeralHomeDetails.name}
                                    {"  "}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <div className={CSS.streamDetails}>
                        <div className={CSS.streamCount}>
                            {funeralHomeDetails.funeralHomeDetails.rooms?.length}
                            {" Rooms"}
                        </div>
                        <div className={CSS.streams}>
                            {funeralHomeDetails.funeralHomeDetails.rooms?.map((room) => (
                                <div key={room.id} className={CSS.eventItem}>
                                    <div className={CSS.eventHeader}>
                                        {room.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> */}
                    {funeralHomeDetails.funeralHomeDetails.users?.length > 0 ?
                        <div className={CSS.streamDetails}>
                            <div className={CSS.streamCount}>
                                {funeralHomeDetails.funeralHomeDetails.users?.length}
                                {" Users"}
                            </div>
                            <div className={CSS.streams}>
                                {funeralHomeDetails.funeralHomeDetails.users?.map((user) => (
                                    <div key={user.id} className={CSS.eventItem}>
                                        <div className={CSS.eventHeader} style={{width : "100%"}}>
                                            {user.email}
                                        </div>
                                        {user.role === "editor_restricted" ?
                                            <div className={CSS.eventTime} style={{marginLeft: "8px", textTransform: "capitalize"}}>
                                                Editor Restricted
                                            </div>
                                        : user.role === "editor"?
                                            <div className={CSS.eventTime} style={{marginLeft: "8px", textTransform: "capitalize"}}>
                                                Editor 
                                            </div>
                                        : user.role === "admin"?
                                            <div className={CSS.eventTime} style={{marginLeft: "8px", textTransform: "capitalize"}}>
                                                Admin 
                                            </div>
                                        :
                                            <div className={CSS.eventTime} style={{marginLeft: "8px", textTransform: "capitalize"}}>
                                                Super Admin 
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        <div className={CSS.streamDetails}>
                            <div className={CSS.streamCount}>
                                No Users
                            </div>
                        </div>
                    }
                </div>
            }
		</Fragment>
	);
};

const StreamControl = ({
	key, room
}) => {
	// console.log("cameraError ", cameraError);
	// const [isStreaming, setIsStreaming] = useState(
	// 	stream.state === "started" || stream.state === "paused"
	// );
	const [modalOpen, setModalOpen] = useState(false);
	// const canStream = stream.livestreamEvent;
	const [streamOpen, setStreamOpen] = useState({});
	const [isCameraRestarting, setIsCameraRestarting] = useState(false);
	const closeModal = () => setModalOpen(false);

	return (
		<div className={CSS.eachStreamData}>
			{/* {loading && <Spinner funeralSpinner={true} />} */}
			<div className={CSS.eachStreamDetails}>
				<div className={CSS.eachStreamName} style={{textTransform: "capitalize"}}>{room.name}</div>
				{/* {stream.state === "not_started" ? (
					<div className={CSS.streamNotStartedState}>
						x Not started
					</div>
				) : stream.state === "started" ? (
					<div className={CSS.streamStartedState}>
						<PlayArrowIcon />
						Started
					</div>
				) : stream.state === "ended" ? (
					<div className={CSS.streamEndedState}>
						<StopCircleIcon />
						Ended
					</div>
				) : stream.state === "paused" ? (
					<div className={CSS.streamPausedState}>
						<PauseCircleIcon />
						Paused
					</div>
				) : stream.state === "starting" ? (
					<div className={CSS.streamStartedState}>Preview</div>
				) : (
					""
				)} */}
			</div>
			<div className={CSS.eachStreamDetails}>
				<div className={CSS.streamStartStopDetails}>
					<div className={CSS.streamData}>
						<span className={CSS.streamDataSubtext}>Name: </span>
						<span className={CSS.streamDataSubtext2}>
							{room.name}
						</span>
					</div>
					<div className={CSS.streamData}>
						<span className={CSS.streamDataSubtext}>RTMP URL: </span>
						<span className={CSS.streamDataSubtext2}>
							{room.rtmpUrl}
						</span>
					</div>
					<div className={CSS.streamData}>
						<span className={CSS.streamDataSubtext}>Stream Provider: </span>
						<span className={CSS.streamDataSubtext2}>
							{room.streamProvider}
						</span>
					</div>
				</div>
                <div className={CSS.streamNotStarted} >
                    Camera not started yet. Please refresh the page to try again.
                </div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
    funeralHomeInfo: state.funeralHome.funeralHomeViewing,
    user: state.user.currentUser,
});

const mapDispatchToProps = {
	getFuneralHome,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FuneralHomeDetailsPage);
